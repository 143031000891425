<template>
    <div>
        <ek-dialog placeholder="ابحث عن المادة" @search="setSearch"></ek-dialog>
    </div>
</template>

<script>
export default {
    methods: {
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: [
                    "name",
                    "lessonCount",
                    "semester",
                    "studentsCount",
                    "teacherCount",
                    "unitCount"
                ],
                query
            });
        }
    }
};
</script>

<style></style>
